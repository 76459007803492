export class UserDetailResponse {
  id: number;
  surname: string;
  name: string;
  birthDay: string;
  username: string;
  phone: string;
  facebookUrl: string;
  avatarPath: string;
  instagram: string;
  telegram: string;
}

